<template>
  <Splash v-if="splashState" />
  <router-view v-if="!splashState" />
  <Loading v-if="loadState" />
</template>

<script setup>
import Splash from "@/views/common/Splash.vue";
import Loading from "@/views/common/Loading.vue";
import bus from "@/utils/bus";
import tools from "@/utils/tools";
import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { ref, nextTick } from "vue";
import { onMounted } from "vue";
import log from "./utils/logger";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import { useRouter } from "vue-router";
const { isLogged } = useGetters("tcp", ["isLogged"]);
const { getLobbyInfo } = useActions("global", ["getLobbyInfo"]);
const { init } = useActions("global", ["init"]);
const { connect } = useActions("tcp", ["connect"]);
const { autoLogin, doLogin } = useActions("user", ["autoLogin", "doLogin"]);
const { resetGame } = useActions("global", ["resetGame"]);
const { uuid } = useGetters("global", ["uuid"]);
const { setVideoCode } = useMutations("global", ["setVideoCode"]);
const router = useRouter();

const hasLoad = ref(false); //是否拿到了基础配置
const splashState = ref(true); //默认开启启动加载页
const loadState = ref(false); //通用的加载

//外部链接videoCode参数保存
let videoCode = tools.getQueryParam("videoCode");
if (!videoCode) {
  videoCode = 'qiuai';
}
// videoCode = 'qiuai',
setVideoCode(videoCode ? videoCode : "");

if (videoCode) {
  /**开始 */
  start();
}

bus.off(bus.event.showLoading);
bus.on(bus.event.showLoading, (bool) => {
  loadState.value = bool;
});

async function start() {
  await getLobbyInfo();
  hasLoad.value = true;
  await init();
  let params = {
    platform: tools.platform(),
    uuid: uuid.value,
    uuid_web: uuid.value,
  };
  await doLogin({ params, url: http.userLogin });
  splashState.value = false;
  // try {
  //   await autoLogin();
  //   splashState.value = false;
  // } catch{
  //   let params = {
  //     platform: tools.platform(),
  //     uuid: uuid.value,
  //     uuid_web: uuid.value,
  //   }
  //   await doLogin({ params, url: http.userLogin });
  //   splashState.value = false;
  // }
}
</script>
<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  background: #f3f4f4;
}

#app {
  margin: auto;
  width: 100%;
  --max-width: 768px;
  max-width: var(--max-width);
  height: 100%;
  font-size: 14px !important;
  background: #fff;
}
</style>
