// using ES6 modules
import mitt from "mitt";
const emitter = mitt();
emitter.event = {
  showLoading: "showLoading",
  resetGame: "resetGame",
  resgisterPop: "resgisterPop",
  loginPop: "loginPop",
  commonTip: "commonTip",
  noticePop: "noticePop",
  memberPop: "memberPop",
  commonTipPop: "commonTipPop",
  promotionPop: "promotionPop",
  promoApplyPop: "promoApplyPop",
  promoUnlockPop: "promoUnlockPop",
  systemInfoPop: "systemInfoPop",
  systemMailPop: "systemMailPop",
  systemMailNtf: "systemMailNtf",
  bankSelectPop: "bankSelectPop",
  getBankSelectInfo: "getBankSelectInfo",
  comInfoPop: "comInfoPop",
  walletPop: "walletPop",
  jumpToTop: "jumpToTop",
  mobileVerifePop: "mobileVerifePop",
  openLiveChat: "openLiveChat",
  vipUpdateNtf: "vipUpdateNtf",
  videoUnLockNtf: "videoUnLockNtf",
  playVideoNtf: "playVideoNtf",
};
export default emitter;
