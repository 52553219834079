import store from "@/store";
import tools from "@/utils/tools";
import { req, qs } from "@/utils/request";
import i18n from "@/language/i18n";
import { encryptBy, decryptBy } from "@/utils/crypto";
import router from "@/router";

class Http {
  env = process.env.NODE_ENV;
  versionConf = "/version/conf";
  userRegister = "/user/register";
  userLogin = "/user/login";
  tokenBind = "/user/token_bind";
  lobbyConf = "/lobby/conf";
  appVersion = "/version/get?app_key=vnFirstPPdwckd"; //版本管理
  apiLogin = "/apiLogin";
  activity = "/activity/getActivityConf";
  errReport = "/data/err";
  captcha = "/captcha";
  smsSend = "/sms/send";
}
class Topic {
  tcpLogin = "lobby/HD_login";
  wallet = "lobby/HD_Wallet";
  userInfo = "lobby/HD_GetBasicInfo";
  bankList = "pay/HD_douDouBtList";
  bindBank = "pay/HD_BindBtCard";
  bindPhone = "lobby/HD_bindPhone";

  payInfo = "pay/HD_payInfo";
  recharge = "pay/HD_charge";
  giftCode = "pay/HD_giftCode";
  withDraw = "pay/HD_douDou";
  gameRecord = "lobby/HD_QueryBetRecord"; //游戏记录
  billRecord = "lobby/HD_GetBills"; //账单记录
  mailList = "lobby/HD_GetMailList";
  updateMail = "lobby/HD_UpdateMailState";
  deleteMail = "lobby/HD_DeleteMail";
  notice = "lobby/HD_Notice";
  discount = "activity/HD_ActivityDoudou";
  betRecord = "lobby/HD_QueryValidBetRecord"; //检查流水
  changePW = "lobby/HD_ModifyPassword";
  payCharge = "pay/HD_charge";
  payDoudou = "pay/HD_douDou";
  payDoudouBtList = "pay/HD_douDouBtList";
  getVIPConfig = "activity/HD_GetVipActivity";
  getVIPScore = "activity/HD_ReceiveVipGiftAll";
  getMonthGift = "activity/HD_ReceiveVipWeekGift";
  getGiftRecord = "activity/HD_QueryVipRecord";
  activityGetJoin = "activity/HD_ActivityGetJoin";
  activityJoin = "activity/HD_ActivityJoin";
  getActivityUserInfo = "activity/HD_GetActivityUserInfo";
  receiveFirstChargeGift = "activity/HD_ReceiveFirstChargeGift";
  receiveSign = "activity/HD_ReceiveSign";
  receiveFishMan = "activity/HD_ReceiveFishMan";
  receiveRegist = "activity/HD_ReceiveRegist";
  setBirthday = "lobby/HD_SetBirthday";
  receiveMemberAll = "activity/HD_ReceiveMemberAll";
  receiveSportWin = "activity/HD_ReceiveSportWin";
  restartSportWin = "activity/HD_RestartSportWin";
  receiveCharge300 = "activity/HD_ReceiveCharge300";
  receiveLobby = "activity/HD_ReceiveLobby";
  startTurnTable = "activity/HD_StartTurnTable";
  getGuessSportStatus = "activity/HD_GetGuessSportStatus";
  guessSportActivity = "activity/HD_GuessSportActivity";
  receiveSportGuess = "activity/HD_ReceiveSportGuess";

  getTurnTableConf = "activity/HD_GetTurnTableConf";
  turnTableRecord = "activity/HD_TurnTableRecord";
  updateDoudouPwd = "lobby/HD_UpdateDoudouPwd";
  collectGame = "lobby/HD_CollectGame";
  cancelCollectGame = "lobby/HD_CancelCollectGame";
  getCollectGame = "lobby/HD_GetCollectGame";

  guestGetInfo = "lottery/HD_guestGetInfo";
  setAvatar = "lobby/HD_SetAvatar";
  record = "lottery/HD_record";
  syncTime = "lottery/HD_syncTime";
  addBet2 = "lottery/HD_addBet2";
  getBetRecordList = "lottery/HD_getBetRecordList";
  getTreasureBowlUserInfo = "activity/HD_GetTreasureBowlUserInfo";
  getTreasureBowlConf = "activity/HD_GetTreasureBowlConf";
  receiveTreasureBowl = "activity/HD_ReceiveTreasureBowl";

  chatJoinGroup = "chat/HD_joinGroup";
  chatSend = "chat/HD_send";
  chatChangePush = "chat/HD_changePush";
  robRedEnvelop = "chat/HD_robRedEnvelop";
  chatMsgLog = "chat/HD_msgLog";
  setUserInfo = "lobby/HD_SetUserInfo";
  queryBetRecordByGameType = "lobby/HD_QueryBetRecordByGameType"; //游戏记录，根据类型
  queryValidBetRecordByGameType = "lobby/HD_QueryValidBetRecordByGameType"; //检查流水，根据类型
  setPhone = "lobby/HD_SetPhone";
  setMail = "lobby/HD_SetMail";
  getVipActivityConf = "activity/HD_GetVipActivityConf";
  receiveVipByType = "activity/HD_ReceiveVipByType"; //aType
  chargeThird = "pay/HD_chargeThird";
  userApplyJoinAct = "actModule/HD_UserApplyJoinAct";
  queryUserJoinedActs = "lobby/HD_QueryUserJoinedActs"; //获取用户参加的活动信息
  userLeaveAct = "actModule/HD_UserLeaveAct"; // 申请完成活动
  vipNewActivity = "activity/HD_GetVipNewActivity";
  receiveVipNewByType = "activity/HD_ReceiveVipNewByType";
  queryVipRecord = "lobby/HD_QueryVipRecord";
  chargeNew = "pay/HD_chargeNew";
  douDouNew = "pay/HD_douDouNew";
  getUserInfoCq = "lobby/HD_GetUserInfoCq";
  setUserInfoCq = "lobby/HD_SetUserInfoCq";
  setVerifyPhone = "lobby/HD_VerifyPhone";
  //视频播放
  getVideoList = "lobby/HD_getVideoList";
  getCityInfo = "lobby/HD_getCityInfo";
  getCityGirlRes = "lobby/HD_getCityGirlRes";
  getVideoKind = "lobby/HD_getVideoKind"; //所有视频分类
  getVideoNum = "lobby/HD_getVideoNum"; // 获取剩余观看次数
  addVideoNum = "lobby/HD_addVideoNum"; //播放次数+1
  getVideoCombo = "lobby/HD_getVideoCombo";
  unLockVideo = "lobby/HD_UnLockVideo";
  collectData = "lobby/HD_CollectData";
}
class Status {
  hide = -1;
  open = 1;
  maintena = 2;
  pre = 3;
}
const http = new Http();
const topic = new Topic();
const status = new Status();
const getApiLoginUrl = function (
  FactoryName,
  GameCode,
  Language = "vi",
  DriveType
) {
  let token = store.getters["user/token"].AccessToken;
  let host = store.getters["user/config"].host;
  if (!DriveType) {
    DriveType = tools.platform() == "web" ? "1" : "0";
  }
  var Languages = tools.getLanguage();
  let params = {
    Token: token,
    FactoryName: FactoryName,
    GameCode: GameCode,
    Language: Languages,
    DriveType: DriveType,
    t: tools.timestampMillisec(),
  };
  return host + http.apiLogin + "?" + qs.stringify(params);
};

const checkFactoryUrl = function (FactoryName, GameCode, Language = "vi") {
  let token = store.getters["user/token"].AccessToken;
  let host = store.getters["user/config"].host;
  let params = {
    Token: token,
    FactoryName: FactoryName,
    GameCode: GameCode,
    Language: Language,
    DriveType: tools.platform() == "web" ? "1" : "0",
    t: tools.timestampMillisec(),
  };
  return host + "/checkFactory" + "?" + qs.stringify(params);
};

export { http, topic, status, getApiLoginUrl, checkFactoryUrl };
