import { theme } from "@/views/theme";

let rootChilren = [
  {
    path: "/",
    name: "main",
    component: () => import("@/views/" + theme + "/components/Main.vue"),
  },
];
let routes = [
  {
    path: "/",
    name: "rootView",
    component: () => import("@/views/" + theme + "/RootView.vue"),
    children: rootChilren,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];
export { routes };
