import { getCurrentInstance, registerRuntimeCompiler } from "vue";
import numeral from "numeral";
import useClipboard from "vue-clipboard3";
import { Toast } from "vant";
import store from "@/store";
import i18n from "@/language/i18n";
import log from "./logger";
let tools = {
  datePipeTs: function (timestamp, format = "yyyy-MM-dd hh:mm:ss") {
    let date = new Date(timestamp);
    return this.datePipe(date, format);
  },
  datePipeStr: function (str, format = "yyyy-MM-dd hh:mm:ss") {
    let date = new Date(Date.parse(str));
    return this.datePipe(date, format);
  },
  datePipe: function (date, format = "yyyy-MM-dd hh:mm:ss") {
    if (isNaN(date)) {
      date = new Date();
    }
    var o = {
      "y+": date.getFullYear(),
      "M+": date.getMonth() + 1, // 月份 "d+": value.getDate(), // 日
      "d+": date.getDate(),
      "h+": date.getHours(), // 小时 "m+": value.getMinutes(), // 分 "s+": value.getSeconds(), // 秒
      "m+": date.getMinutes(),
      "s+": date.getSeconds(),
    };
    if (/(y+)/.test(format)) {
      format = format.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    }
    for (var k in o) {
      if (new RegExp("(" + k + ")").test(format)) {
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
    return format;
  },
  platform: function () {
    let u = navigator.userAgent;
    if (typeof xapp != "undefined") {
      if (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) {
        return "android";
      } else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        return "ios";
      } else {
        return "native";
      }
    } else if (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) {
      return "h5_android";
    } else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      return "h5_ios";
    } else {
      return "web";
    }
  },
  getQueryVariable: function (variable) {
    let href = window.location.href;
    let query = href.substring(href.indexOf("?") + 1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  },
  isNative() {
    return typeof xapp != "undefined";
  },
  isMobile() {
    if (this.getQueryVariable("mm") == "1") return true;
    return (
      this.platform() == "h5_android" ||
      this.platform() == "h5_ios" ||
      this.isNative()
    );
  },
  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  },
  timestampMillisec() {
    var timestamp2 = new Date().valueOf();
    return timestamp2;
  },
  formatNum2k(num) {
    if (num < 1000) {
      return num / 1000;
    }
    num = num / 1000;
    return numeral(num)
      .format("0,0.000")
      .substr(0, numeral(num).format("0,0.000").length - 1);
  },
  formatNum3k(num) {
    if (num < 1000) {
      return num / 1000;
    }
    num = num / 1000;
    return numeral(num).format("0,0.000");
  },
  formatNum(num) {
    return numeral(num).format("0,000");
  },
  formatMoney(num) {
    return this.numberUnit(num, 2, false);
  },
  numberUnit(num, digits, needObj = true) {
    const si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    if (needObj)
      return {
        value: (num / si[i].value).toFixed(digits).replace(rx, "$1"),
        symbol: si[i].symbol,
      };
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  },
  copyText(text) {
    const { toClipboard } = useClipboard();
    return toClipboard(text);
  },
  toast(text) {
    Toast(text);
  },
  success(text) {
    Toast.success(text);
  },
  loading(text) {
    Toast.loading({
      duration: 0,
      message: text + "...",
      forbidClick: true,
      icon: "",
      loadingType: "spinner",
    });
  },
  dismissLoading(all = true) {
    Toast.clear(all);
  },
  systemLanguage() {
    let lang = navigator.language || navigator.userLanguage;
    return lang.substr(0, 2);
  },
  getLanguageList() {
    const langList = [
      {
        flag: "en",
        lable: "English",
        img: require("@/assets/common/lang/en.png"),
      },
      {
        flag: "vi",
        lable: "Tagalog",
        img: require("@/assets/common/lang/ph.png"),
      },
      {
        flag: "zh",
        lable: "中文",
        img: require("@/assets/common/lang/zh.png"),
      },
    ];
    return langList;
  },
  getLanguage() {
    let lang = i18n.global.locale;
    let language = lang.value;
    let pin = "";
    if (
      language == "zh-cn" ||
      language == "zh-CN" ||
      language == "cn" ||
      language == "zh"
    ) {
      pin = "zh";
    } else {
      pin = language;
    }
    return pin;
  },
  getCurLangUnit() {
    let lang = this.getLanguage();
    let unitStr = "英文货币";
    switch (lang) {
      case "zh":
        unitStr = "中文货币";
        break;
      case "vi":
        unitStr = "越南货币";
        break;
      case "in":
        unitStr = "印度货币";
        break;
      case "en":
        unitStr = "英文货币";
        break;
    }
    return unitStr;
  },
  formatDate(str, format = "yyyy-MM-dd hh:mm") {
    return new Date(Date.parse(str)).Format(format);
  },
  isRelease() {
    return process.env.NODE_ENV == "production";
  },
  winWidth() {
    return document.documentElement.clientWidth;
  },
  getFactoryName(name) {
    switch (name) {
      case "apiCq":
        name = "apiCq9";
        break;
      case "apiAwc":
        name = "apiAe";
        break;
    }
    let temp = name.slice(3).toUpperCase();
    return temp;
  },
  HTTPIsBoolean(str) {
    if (/^https?:\/\//.test(str)) {
      return true;
    } else {
      return false;
    }
  },
  getQueryParam(paramName) {
    let url = window.location.href.slice(1);
    let urlArr = url.split("?");
    for (let key in urlArr) {
      let info = new URLSearchParams(urlArr[key]);
      if (info.get(paramName)) {
        return info.get(paramName);
      }
    }
    return null;
  },

  deepClone(obj) {
    let objClone = Array.isArray(obj) ? [] : {};
    if (obj && typeof obj === "object") {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          //判断ojb子元素是否为对象，如果是，递归复制
          if (obj[key] && typeof obj[key] === "object") {
            objClone[key] = this.deepClone(obj[key]);
          } else {
            //如果不是，简单复制
            objClone[key] = obj[key];
          }
        }
      }
    }
    return objClone;
  },
  reload() {
    location.reload();
  },

  /**
   * 获取日期范围
   */
  getDateByType(val) {
    const now = new Date(); // 当前日期
    const nowDayOfWeek = now.getDay(); // 今天本周的第几天
    const nowDay = now.getDate(); // 当前日
    const nowMonth = now.getMonth(); // 当前月
    const nowYear = now.getFullYear(); // 当前年
    const jd = Math.ceil((nowMonth + 1) / 3);
    let startTime;
    let endTime;
    switch (val) {
      case "today": // 今天
        startTime = new Date();
        endTime = new Date();
        break;
      case "yesterday": // 昨日
        startTime = new Date(nowYear, nowMonth, nowDay - 1);
        endTime = new Date(nowYear, nowMonth, nowDay - 1);
        break;
      case "pastWeek": // 近 7 日
        startTime = new Date(nowYear, nowMonth, nowDay - 6);
        endTime = new Date(nowYear, nowMonth, nowDay);
        break;
      case "week": // 本周
        startTime = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
        endTime = new Date(nowYear, nowMonth, nowDay + 6 - nowDayOfWeek);
        break;
      case "lastWeek": // 上周
        startTime = new Date(
          nowYear,
          nowMonth,
          nowDay - (nowDayOfWeek == 0 ? 7 : nowDayOfWeek)
        );
        endTime = new Date(
          nowYear,
          nowMonth,
          nowDay + 6 - (nowDayOfWeek == 0 ? 7 : nowDayOfWeek)
        );
        break;
      case "month": // 本月
        startTime = new Date(nowYear, nowMonth, 1);
        endTime = new Date(nowYear, nowMonth + 1, 0);
        break;
      case "lastMonth": // 上月
        startTime = new Date(nowYear, nowMonth - 1, 1);
        endTime = new Date(nowYear, nowMonth, 0);
        break;
      case "quarter": // 本季度
        startTime = new Date(nowYear, (jd - 1) * 3, 1);
        endTime = new Date(nowYear, jd * 3, 0);
        break;
      case "year": // 今年
        startTime = new Date(nowYear, 0, 1);
        endTime = new Date(nowYear, 11, 31);
        break;
    }
    return [startTime, endTime];
  },

  // * obj排序 对map的进行排序
  //sortArr:[]，按数组顺序取值sortkey进行排序
  sortObject(map, sortArr) {
    let arr = [];
    for (let key in map) {
      arr.push({
        name: key,
        info: map[key],
      });
    }
    //要求太深入的排序自己单独处理
    if (sortArr.length <= 0 || sortArr.length > 2) {
      return map;
    }
    if (sortArr.length == 1) {
      arr.sort((a, b) => {
        return a.info[sortArr[0]] - b.info[sortArr[0]];
      });
    } else if (sortArr.length == 2) {
      arr.sort((a, b) => {
        return a.info[sortArr[0]][sortArr[1]] - b.info[sortArr[0]][sortArr[1]];
      });
    }
    let newMap = new Map();
    for (let i = 0; i < arr.length; i++) {
      newMap.set(arr[i].name, arr[i].info);
    }
    return Object.fromEntries(newMap);
  },
  //自定义字符串转换
  stringFormat(formatted, args) {
    for (let i = 0; i < args.length; i++) {
      let regexp = new RegExp("\\[" + i + "\\]", "gi");
      formatted = formatted.replace(regexp, args[i]);
    }
    return formatted;
  },
  getNormalImg(url) {
    let lobby = store.getters["global/lobbyInfo"];
    return lobby.HotDomain + url;
  },
};

export default tools;
