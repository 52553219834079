let theme_m = process.env.VUE_APP_UI_MOBILE;
let theme_pc = process.env.VUE_APP_UI_ASSETS_PC;

import tools from "@/utils/tools";

// let theme = theme_pc
// if (tools.isMobile()) {
//     theme = theme_m
// }
let theme = theme_m;
export { theme };
