<template>
  <div class="loading">
    <div class="toast-loading">
      <div class="body">
        <SvgaPlayer class="svgaPlayer" :id="'loadingSvga'" :svgaUrl="btn_loading_svga" :svgaWidth="220" :svgaHeight="220">
        </SvgaPlayer>
      </div>
      <div class="img-wp">
        <img src="@/assets/common/logo.png" alt="">
      </div>
    </div>
  </div>
</template>

<script setup>
import SvgaPlayer from "@/views/common/SvgaPlayer.vue";
import btn_loading_svga from "@/assets/common/btn_loading.svga";
import bus from '@/utils/bus'
import { onMounted } from "vue";
import { onBeforeUnmount } from "vue";
let timeFun = null;
onMounted(() => {
  //10s 加载缓冲
  timeFun = setTimeout(() => {
    bus.emit(bus.event.showLoading, false);
  }, 10000)
})
onBeforeUnmount(() => {
  timeFun && clearTimeout(timeFun);
})
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  // background-color: transparent;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 999991;
  width: 100%;
  height: 100%;
  // pointer-events: none;

  .toast-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: content-box;
    width: 88px;
    max-width: 70%;
    min-height: 88px;
    padding: 16px;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
    text-align: center;
    word-break: break-all;
    border-radius: 8px;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    // background-color: rgba(0, 0, 0, 0.7);
    background: radial-gradient(
      100% 100% at 50.02% 0,
      #c9f9ff 0,
      #73b0ff 60%,
      #6d6bfb 100%
    );
    opacity: 0.7;
    z-index: 999993;

    .body {
      position: relative;
      width: 100%;
      height: 88px;

      .svgaPlayer {
        background-color: transparent;
        transform: matrix(0.4, 0, 0, 0.4, -66, -66);
      }
    }

    .img-wp {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      border-radius: 20px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
}
</style>